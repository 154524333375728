<!--已审核待支付-->
<template>
  <div class="orderpay_page">
    <div class="red-box">
      <p>请在就诊时段前完成支付，超时订单将取消</p>
    </div>
    <div class="page-box">
      <div class="card-box">
        <div class="cell">
          <div class="cell_title">
            订单号
          </div>
          <div class="cell_value middle-color">
            {{ details.ddh }}
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            订单名称
          </div>
          <div class="cell_value middle-color">
            {{ details.ddlxmc }}
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            应付金额
          </div>
          <div class="cell_value red-color">
            ¥ {{ details.zje }}
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="title">
          选择支付方式
        </div>
        <div class="cell_pay">
          <van-radio-group v-model="wxChecked" checked-color="#09BB07">
            <div class="pay_cell" @click="wxChecked == '1'">
              <div class="cell_title">
                <van-icon name="wechat-pay" class="icon green-color size32" />
                微信支付
              </div>
              <van-radio name="1" />
            </div>
            <div v-if="orderTypeKey!=='14'" class="pay_cell" @click="wxChecked == '2'">
              <div class="cell_title">
                <img src="../../../assets/img/gjc_yibao.png" class="icon_img">
                医保支付
              </div>
              <van-radio name="2" />
            </div>
          </van-radio-group>
        </div>
      </div>
      <div class="btn-box">
        <!-- <div class="box_title">
          温馨提示：
        </div> -->
        <div class="box_cont" v-html="hospInfo.content" />
      </div>
    </div>
    <div>
      <GjcPay v-if="wxChecked == '1'" :price="details.zje" :text="text" :order-id="details.orderId"
              :order-type-key="orderTypeKey" @success="onPaySuccess"
      />
      <GjcYBPay v-if="wxChecked == '2'" :price="details.zje" :order-id="details.orderId"
                :order-type-key="orderTypeKey" @success="onPaySuccess"
      />
    </div>
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import GjcPay from '@/components/pay/gjc-pay'
import GjcYBPay from '@/components/pay/gjc-ybpay'

export default {
    components: { GjcPay, GjcYBPay },
    data() {
        return {
            details: {},
            orderId: -1,
            wxChecked: '1', //默认1:微信支付 2:医保支付
            text: '',
             //1挂号单2处方单11挂缴查门诊缴费12挂缴查体检缴费13挂缴查预约挂号缴费14挂缴查预缴金缴费
            orderTypeKey: '',
            hospInfo:{}
        }
    },
    mounted() {
        this.orderId = +this.$route.query.id
        // this.orderTypeKey = this.$route.query.type || ''
        // if (this.orderTypeKey !== '13') {
        //   this.text = "" 
        // }
        this.mzPayDetail()
        this.gjcDepartDesc()
    },
    methods: {
      gjcDepartDesc(){
          gjcModel.gjcDepartDesc('ZFXZ').then((result) => {
                this.hospInfo = result.data
            })
        },
        mzPayDetail() {
            gjcModel.mzPayDetail(this.orderId).then(res => {
                this.details = res.data || {}
                this.orderTypeKey = res.data.businessType || ''
                if (this.orderTypeKey !== '13') {
                  this.text = "" 
                }
            })
        },
        onPaySuccess() {
            this.$router.push({ 
              path: '/paySuccess' ,
              query: {
                orderId: this.orderId
              }
            })
        }
    }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";

.orderpay_page {
    background-color: #f1f1f1;
}

.red-box {
    width: 100%;
    height: 168px;
    background: #1e80ff;

    p {
        color: #ffffff;
        margin: 0;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 88px;
    }
}

.page-box {
    padding: 0 32px 50px;
    box-sizing: border-box;
    position: relative;
    top: -80px;

    .card-box {
        width: 100%;
        background: #fff;
        border-radius: 12px;
        padding: 30px 32px;
        margin-bottom: 24px;
        box-sizing: border-box;
        z-index: 2;

        .tit {
            width: 100%;
            color: @dark-font-color;
            padding-bottom: 20px;
            text-align: center;
            font-size: 28px;
            font-weight: bold;
        }

        .title {
            font-size: 32px;
            font-weight: bold;
            color: @dark-font-color;
        }

        .radio-group {
            display: flex;
            justify-content: space-between;

            .radio-btn {
                width: 176px;
                height: 48px;
                line-height: 48px;
                color: #999999;
                text-align: center;
                border-radius: 24px;
                border: 1px solid #999999;

                &.active {
                    color: @theme-color;
                    border: 1px solid @theme-color;
                }
            }
        }

        .cell {
            position: relative;
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 0;
            font-weight: 400;
            overflow: hidden;
            color: @dark-font-color;
            font-size: 28px;
            line-height: 40px;
        }

        .cell_title,
        .cell_value {
            flex: 1 1 auto;
        }

        .cell_value {
            overflow: hidden;
            text-align: right;
        }
    }

    .icon {
        position: relative;
        top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        width: 30px;
        height: 18px;
    }
}

.fl-right {
    float: right;
    margin-top: 6px;
}

.cell_pay {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 0 0 0;
    font-weight: 400;
    overflow: hidden;
    color: @dark-font-color;
    font-size: 28px;
    line-height: 40px;

    .pay_cell {
        display: flex;
        margin-top:30px;
        justify-content: space-between;
    }

    .cell_title,
    .cell_value {
        flex: 1 1 auto;
    }
}
.box_title{
  padding: 20px;
  font-size: 30px;
  font-weight: bold;
  color: red;
}

.box_cont{
  padding: 0 20px;
  font-size: 26px;
  color: #666;
}

.icon_img {
    width: 60px;
    height: 18px;
}
</style>
